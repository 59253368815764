.main_content {
  background-color: var(--bg-color);
  min-height: 50vh;
  margin-bottom: 30px;
  padding: 25px 30px !important;

  .section_title {
    position: relative;
    display: inline-block;
    padding: 0 3px 0;
    margin-bottom: 24px;
    color: var(--text-color) !important;

    .title_text {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      position: relative;
    }
    span {
      position: absolute;
      height: 16px;
      width: 90%;
      bottom: -2px;
      left: -4px;
      border-bottom: 2px solid var(--bg-two-color);
      border-left: 2px solid var(--bg-two-color);
    }
  }

  .about_me_text {
    text-align: justify;
    color: var(--text-color);
    font-size: smaller;
    line-height: 22px !important;
    margin-bottom: 10px;
  }
  .about_motiv_text {
    text-align: right;
    color: var(--text-color);
    font-size: smaller;
    margin-bottom: 5px;
    line-height: 22px !important;
    font-style: italic;
    font-weight: 500;
  }

  .picture_container {
    margin: 20px 0;
    .skills_img {
      animation-duration: 60s;
      animation-iteration-count: infinite;
      &:nth-of-type(8) {
        animation-name: rotateSvg1;
      }

      img {
        padding: 5px;
        width: 100%;
        height: auto;
      }
    }
  }

  .section {
    margin: 10px 0 30px;
    padding: 0 10px;
    .work_timeline_title {
      font-weight: 500;
      margin-top: -4px;
      text-align: left;
      color: var(--text-color);
    }
    .work_timeline_date {
      color: var(--text-color);
    }
    .work_timeline_info {
      font-size: small;
      color: var(--text-passive-one);
      text-align: left;
      padding-top: 8px;
    }
  }
}

@keyframes rotateSvg1 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
