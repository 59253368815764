.contactInfo_item {
  span {
    font-weight: bold;
  }
}
.MuiInput-underline:after {
  border-bottom: 2px solid var(--bg-two-color) !important;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--bg-two-color) !important;
}
.contact_socials {
  margin-top: 5px;
  a {
    text-decoration: none;
    color: var(--text-color) !important;
    margin-right: 10px;
    font-size: 18px;

    &:hover svg {
      transform: scale(1.1);
    }
  }
}
.input_alert {
  transition: all 0.5s ease;
}
