* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

:root {
  --bg-site: #eff2f8;
  --bg-color: rgb(255, 255, 255);
  --bg-two-color: #ffbe0f;
  --text-color: #373d3f;
  --text-passive-one: #8c979a;
  --text-passive-two: #8c979a44;
  --shadow: rgba(149, 157, 165, 0.2);
}
html {
  scroll-behavior: smooth;
}
body::-webkit-scrollbar {
  width: 4px;
}
body::-webkit-scrollbar-track {
  background: var(--text-passive-two);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--bg-two-color);
  border-radius: 20px;
  border: 1px solid var(--text-passive-two);
}

img {
  max-width: 100%;
  vertical-align: middle;
}

body {
  background-color: var(--bg-site) !important;
  overflow-x: hidden;
}
.particles-bg-canvas-self {
  background: var(--bg-site);
}
.container_shadow {
  box-shadow: var(--shadow) 0px 8px 24px;
  -webkit-box-shadow: var(--shadow) 0px 8px 24px;
  -moz-box-shadow: var(--shadow) 0px 8px 24px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.all_component {
  padding-top: 30px;
}

@media (max-width: 900px) {
  .all_component {
    padding-top: 20px;
  }
  .hireMe {
    background-color: lawngreen !important;
  }
}
.dropdown-menu {
  min-width: 5rem !important;
  .dropdown-item {
    padding-left: 5px;
  }
}
.loading_spinner {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
