.site-btn {
  background-color: var(--bg-two-color) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: 0 !important;
  border-radius: 50px !important;
  padding: 4px 8px !important;
  line-height: 0 !important;
  outline: none !important;
  min-width: 100px !important;
  transition: all 0.3s ease;
  .btn_icon {
    color: var(--text-color) !important;
    background-color: var(--bg-color) !important;
    border-radius: 50px !important;
    height: 28px;
    width: 28px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px !important;
    animation: btndown 1s linear 0 alternate;
  }
  .btn_text {
    text-transform: none !important;
    font-size: 14px !important;
    text-align: center;
    width: 100%;
    margin-right: 4px;
    margin-left: 10px;
  }

  &:hover {
    color: var(--text-color) !important;
    .btn_icon {
      animation-iteration-count: infinite;
    }
    &:hover {
      background-image: linear-gradient(
        60deg,
        var(--bg-two-color) 60%,
        var(--bg-color) 70%,
        transparent 80%,
        var(--bg-two-color) 100%
      );
      animation: slidebg 3s linear infinite;
    }
  }
}

@keyframes btndown {
  0% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-1px);
  }

  70% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0px);
  }
}
@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}
